import React from 'react'
import ContactForm from '../../components/blocks/forms/ContactForm'
import Contacts from '../../components/blocks/index/Contacts/Contacts'
import Map from '../../components/blocks/index/Map/Map'
import Extender from '../../components/ui/Extender'
import {sendContactForm} from '../../services/user'
import mitreDoc from '../../assets/MITRE_ATTACK_CVA.xlsx'

import './style.scss'

const xdrPage = ({history}) => {
  const submitForm = data => sendContactForm(data).then(response => {})
  const MODULES = [
    {
      name: 'NGDRC',
      desc: 'Подсистема выявления киберугроз и реагирования на них',
      items: [
        'Отображение информации о вашей сети (Карта сети)',
        'Вывод на экран администратора обобщенного графа атак в режиме реального времени',
        'Интерактивная карта мира с отображением киберугроз, которым подверглась ваша сеть ',
        'Карта нейросетевой активности атак',
        'Вывод ТОП атак по типу',
        'Вывод ТОП атакующих ip-адресов',
        'Демонстрация графика атак за месяц',
        'Отображение активности киберугроз за 48 часов',
        'Статистика активности вредоносных событий электронной почты',
        'Сканирование беспроводных сетей в режиме реального времени',
        'Сбор и интеллектуальный анализ данных из множества подсистем систем',
        'Реагирование на сбои при регистрации событий безопасности, в том числе аппаратные и программные ошибки, сбои в механизмах сбора информации и достижение предела или переполнения объема (емкости) памяти',
        'Мониторинг (просмотр, анализ) результатов регистрации событий безопасности и реагирование на них',
        'Обнаружение несанкционированных действий',
        'Сбор, запись и хранение информации о событиях безопасности в течение установленного времени хранения',
        'Генерирование временных меток и (или) синхронизация системного времени в информационной системе',
        'Защита информации о событиях безопасности',
        'Возможность удаленного управления подсистемой с АРМ администратора безопасности с территории заказчика',
        'Протокол действий операторов и администраторов',
      ],
    },
    {
      name: 'NGIPS',
      desc: 'Подсистема предотвращения вторжений',
      items: [
        'Автоматическое реагирование на атаки',
        'Защита серверов и рабочих станций от НСД',
        'Создание правил реагирования на атаки',
        'Интеграция в систему NGVPN',
      ],
    },
    {
      name: 'REPORTS',
      desc: 'Подсистема отчётов',
      items: [
        'Возможность генерации системных отчетов: по пользователю, группе, оборудованию компании, реагированию на события',
        'Создание отчетов по событиям Honeypot и Waf',
        'Построение интегральных отчетов статистики атак, сохранение их в формат PDF',
        'Построение интегральных отчетов по рабочему времени пользователей, сохранение их в формат PDF',
        'Построение досье пользователя с указанием активности в приложениях и ТОПа посещаемых сайтов, сохранение отчета в формат PDF',
      ],
    },
    {
      name: 'NGWAF',
      desc: 'NG Web Application Firewall',
      items: [
        'Отображение исчерпывающей информации об атакующем IP-адресе',
        'Возможность блокировки атакующего IP-адреса',
        'Создание правил реагирования на события',
        'Добавление IP-адреса в список исключений',
        'Защищать от атак, направленных на NF Web Application Firewall',
        'Формирование журнала событий',
        'Реализация гибких иерархических политик доступа пользователей к веб-ресурсам',
        'Категоризатор для блокирования доступа к нежелательному контенту',
        'Блокировка сетевых угроз (L3/L4 модели OSI)',
        'Маршрутизация трафика',
        'Оценка безопасности сайтов в реальном времени',
        'Безопасный доступ в веб-интерфейс управления по HTTPS',
        'Перехват и расшифровка трафика, блокирование атак',
        'Автоматическое выявление уязвимости приложений',
        'Блокировка атак нулевого дня',
        'Возможность блокировки атак, таких как HPP, HPC и XXE',
        'Защита внутреннего и внешнего XML-трафика',
      ],
    },
    {
      name: 'NGSIEM',
      desc: 'Подсистема мониторинга компьютерных инцидентов',
      items: [
        'По каждому атакующему воздействия интеллектуальная система расследования строит граф вектора атаки и связей',
        'Возможность перехода в модуль NGIPS для блокировки атак',
        'Отображение полной информации об атакующем IP-адресе и атакованном устройстве',
      ],
    },
    {
      name: 'NGIDS-N',
      desc: 'Система идентификации киберугроз агентами системы',
      items: [
        'Идентификация целенаправленных и распределенных атак на сетевую инфраструктуру',
        'Сбор данных для идентификации попыток похищения коммерческой и персональной информации по сетевым каналам связи',
        'Сбор данных для расследования инцидентов сетевых вторжений, эксплойтов и попыток удаленного управления сетевой инфраструктурой',
        'Каталогизация и хранение событий безопасности сетевой инфраструктуры',
        'Интеграция в систему NGXDR',
        'Отображение информации о подключенных агентах на устройствах пользователей',
        'Сканирование уязвимостей на конечных устройствах',
        'Выявление любых типов атак, таких как BRUTE, DDOS, EXPLOIT, RANSOMWARE и т.д',
        'Обнаружение неизвестных атак',
        'Реализация метода Honeypot',
        'Автоматическое обнаружение запросов о парольной политике по протоколу SAMR',
        'Нейросетевое обнаружение шифровальщиков',
        'Автоматическое выявление использования популярных утилит сканирования',
        'Обнаружение попыток НСД',
        'Обнаружение признаков модификации файла с помощью "упаковщиков',
      ],
    },

    {
      name: 'NGSM',
      desc: 'Подсистема защиты почты',
      items: [
        'Анализ почтовых отправлений на признаки спама, фишинга, вредоносных ссылок и т.д.',
        'Интеграция в систему NGXDR',
      ],
    },
    {
      name: 'NGLOCK',
      desc: 'Подсистема контроля действий пользователей',
      items: [
        'Анализ содержимого файлов и папок',
        'Предоставление досье на сотрудника',
        'Анализ поведения пользователей',
        'Контроль USB-портов',
        'Учет рабочего времени сотрудников',
        'Автоматические снимки с веб-камеры',
        'Снимки с веб-камеры по расписанию',
        'Автоматические скриншоты рабочего стола',
        'Скриншоты рабочего стола по расписанию',
        'Автоматическая запись окружения с микрофона',
        'Запись окружения с микрофона по расписанию',
        'Кейлоггер',
        'Построение отчетов по всем событиям на конечных устройствах',
        'Поддержка территориально распределенной структуры организации',
        'Мониторинг буфера обмена',
        'Блокировка сетевого взаимодействия при работе с конфиденциальной информации',
        'Явная (не явная) маркировка (защита) скриншотов',
        'Контроль web-доступа и других HTTP/HTTPS-приложения, мессенджеров, социальных сетей',
        'Журналирование всех действий пользователей с устройствами и сетевыми протоколами по факту обращения к ним, передачи файлов',
        'Панель управления расследованиями',
        'Контроль печати документов на принтере (МФУ)',
        'Блокирование сеанса доступа в информационную систему после установленного времени бездействия',
        'Управление запуском (обращениями) компонентов программного обеспечения',
        'Контроль доступа устройств и портов относительно пользователей и групп',
        'Функция установки доступа «только чтение» для МНИ всех типов',
        'Контроль сетевых коммуникаций',
        'Контроль передачи почтовых сообщений по открытым и SSL-защищенным SMTP-сессиям (с раздельным контролем сообщений и вложений), почтовых приложений',
        'Тревожные оповещения об инцидентах безопасности в реальном режиме времени',
        'Теневое копирование файлов и данных',
        'Детектирование и фильтрация типов файлов',
        'Контроль присутствия работников на рабочем месте',
        'Управление запуском (обращениями) компонентов программного обеспечения',
        'Установку (инсталляция) только разрешенного к использованию ПО',
        'Управление группами пользователей',
        'Отображение текущих событий по пользователям и группам пользователей за 48 часов',
        'Мониторинг всех процессов, происходящих на конечных устройствах',
        'Возможность блокировки сетевого взаимодействия пользователей',
        'Отслеживание файлов по расширению',
        'Реализована подсистема закрытого контура (предотвращение утечек конфиденциальной информации)',
        'Возможность присвоения грифа секретности обрабатываемой информации',
        'Настраиваемая блокировка процессов при работе с информацией конфиденциального характера',
        'Контроль удаленного доступа',
        'Кодировка окон (графическая)',
        'Контроль сетевых коммуникаций, включая распознавание сетевых протоколов, детектирование коммуникационных приложений и их селективная блокировка',
        'Белый список сетевых протоколов',
        'Централизованное хранение журналов аудита и теневого копирования',
        'Поддержка offline-политик, применяя один набор политик доступа и аудита для ситуации, когда рабочая станция подключена к корпоративной сети (online), и другой — когда отключена от сети (offline)',
        'Отчеты на основе данных, хранимых в базе данных, а также отчеты по текущим настройкам и по используемым на рабочих станциях устройствам, динамический граф связей для анализа коммуникаций',
        'Отчет по персоне',
        'Архивирование пересылаемых сообщений на случай возможных в будущем расследований инцидентов',
        'Контроль в терминальной сессии (буфера обмена в терминальной сессии, записи на присоединенный терминальный диск)',
        'Работа Агентов независимо по отношению к серверной части',
        'Исключение доступа пользователя к информации, возникшей в результате действий предыдущего пользователя через реестры, оперативную память, внешние запоминающие устройства и иные общие для пользователей ресурсы информационной системы',
      ],
    },
    {
      name: 'NGUSA',
      desc: 'Подсистема единой авторизации пользователей, учтенного оборудования и хранение ключевой информации пользователей',
      items: [
        'Автоматизация процессов управления доступом к информационным системам',
        'Идентификация и аутентификация пользователей/устройств',
        'Управление средствами аутентификации',
        'Управление учетными записями пользователей',
        'Интерфейс мониторинга прав доступа сотрудников к информационным  системам',
        'Система контроля входа пользователей в систему',
        'Разграничение доступа пользователей к устройствам и контроль аппаратной конфигурации',
        'Отображение подробной информации об устройствах ',
        'Контроль несанкционированного использования ресурсов',
        'Белый список USB-устройств',
        'Временный белый список доступа к USB-устройствам при отсутствии сетевого подключения',
        'Управление идентификаторами, в том числе создание, присвоение, уничтожение идентификаторов',
        'Управляющий интерфейс по исполнению процедур управления доступом и подготовку данных для аудита',
        'Система контроля соблюдения регламентов предоставления доступа',
        'Модуль алгоритма снижения избыточных полномочий в информационных системах',
        'Интеграция в систему NGXDR',
        'Техническая поддержка производителя',
        'Возможность удаленного управления подсистемой с АРМ  администратора безопасности с территории заказчика',
        'Протокол действий операторов и администраторов',
      ],
    },
    {
      name: 'NGVPN',
      desc: 'Подсистема защищенного VPN',
      items: [
        'Интеграция с системой NGIDS для идентификации атак в рамках VPN инфраструктуры, идентификация аномального трафика, вторжений в защищенную сеть, наличия бэкдоров и атак предоставления вредоносных шлюзов защищенной сети',
        'Сбор данных для расследования инцидентов в средах VPN',
        'Интеграция в систему NGDLP в рамках управления учетными данными пользователей и доступа к защищенным средам VPN',
        'Контроль пользователей, использующих VPN',
        'Интеграция в систему NGXDR',
        'Возможность удаленного управления подсистемой с АРМ администратора безопасности с территории заказчика',
        'Протокол действий операторов и администраторов',
      ],
    },
    {
      name: 'NGSIM',
      desc: 'Подсистема защищенного корпоративного мессенджера',
      items: [
        'Ведение переписки с максимальным уровнем конфиденциальности',
        'Формирование пользовательских каналов по отделам и темам совместного обмена сообщений и файлов',
        'Работа на мобильных устройствах Android',
        'Интеграция в систему NGDLP в рамках управления учетными данными пользователей',
        'Реализация функциональных характеристик современного корпоративного мессенджера',
        'Интеграция в систему NGXDR',
      ],
    },
  ]
  return (
    <div className="xdr-page">
      <div className="xdr-page__wrapper">
        <div className="xdr-page__top">
          <div className="xdr-page__top-title">
            <h2>NEUROFORTRESS XDR</h2>
            <h4>нового поколения</h4>
          </div>
          <div className="xdr-page__top-desc">
            Система предупреждения и обнаружения атак нового поколения <br></br> со встроенным функционалом
            Next-generation firewall
          </div>
        </div>

        {/* <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title">
              Киберпреступность - бурно развивающийся рынок на мировой арене и в России в частности
            </div>
            <p className="xdr-page__desc">
              Из-за остро необходимого процесса цифровизации предприятий торговли, промышленности, строительства и
              услуг, позволяющей расширяться и оптимизировать процессы и расходы компаний, они все чаще сталкиваются с
              угрозами киберпреступлений.
            </p>
            <p className="xdr-page__quote">
              По данным Сбербанка потери российской экономики от хакерских атак на начало 2022 приблизились к отметки в
              6 триллионов рублей с учётом, что в 2019 цифра была всего 2.5 триллиона. Жертвами преступников все чаще
              становятся предприятия не финансового сегмента, а сектора торговли, промышленности, строительства и услуг
              из-за недостатка защиты и знаний в этой области безопасности.<br></br>С учётом нынешней политической и
              экономической конъюнктуры как внутри страны, так и за рубежом, вместе с угрозой повышенной киберпреступной
              активности со стороны недружественных стран, потери компаний в России могут возрасти в разы.
            </p>
          </div>
        </section> */}

        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title">Угрозы с которыми сталкиваются предприятия:</div>
            <ul className="xdr-page__list">
              <li>Таргетированные кибератаки (целенаправленные атаки на предприятие)</li>
              <li>Кража личных данных с серверов компаний с целью шантажа</li>
              <li>
                Кража логинов и паролей с целью несанкционированного перевода денежных средств с расчетных счетов
                компании
              </li>
              <li>Шифрование данных с целью вымогательства</li>
              <li>
                Уничтожение данных критической инфраструктуры, что может парализовать деятельность всего предприятия
              </li>
              <li>Кража интеллектуальной собственности</li>
              <li>Кража конкурентами баз данных, представляющих коммерческую тайну</li>
              <li>
                Атаки на репутацию и бренд компании с помощью публикации недостоверной информации или разглашения
                компрометирующих данных в информационных источниках компаний
              </li>
            </ul>

            <div className="xdr-page__desc">
              Средний ущерб от одной из перечисленных атак для крупной компании в России в 2021 году составил 695 тысяч
              долларов. В тот же период 35% всех организаций в стране уже столкнулись с такими инцидентами.
            </div>
            <div className="xdr-page__desc">
              На 150% выросло число атак шифровальщиков по сравнению с 2022 годом. Суммарный потенциальный ущерб от атак
              с использованием шифровальщиков составил 1 миллиард долларов.
            </div>
            <div className="xdr-page__desc">
              Также серьезную угрозу представляют DDoS-атаки, вызывающие преднамеренную перегрузку оборудования с целью
              вызвать отказ в обслуживании и парализовать работу предприятия. Средний ущерб от одной такой атаки в
              России в 2021 году составил 463 тысячи долларов.
            </div>
            <div className="xdr-page__quote">
              <div className="xdr-page__sub-title">Вирусы шпионы.</div>
              Данный тип вирусов часто носит массовый характер, полагаясь на поверхностную информационную защищённость
              предприятий. Вопреки общему мнению, сложные вирусы и вирусы с неизвестной сигнатурой не обнаруживаются
              многими антивирусными программами.
            </div>
            <div className="xdr-page__sub-title">Недостатки известных систем</div>
            <ul className="xdr-page__list">
              <li>
                Стандартные средства обеспечения кибербезопасности не фиксируют атаки с неизвестной или нестабильной
                сигнатурной структурой (неизвестные атаки)
              </li>
              <li>
                Отсутствие интегральных систем безопасности, включающих беспроводные сети и среды умных устройств IoT
                (интернет-вещей)
              </li>
              <li>
                Существующие реализации эвристических механизмов обнаружения имеют большое количество ложных
                срабатываний
              </li>
              <li>
                Недостаточная скорость работы механизмов обнаружения атак, задержки при обработке большого трафика
              </li>
              <li>Идентификационные базы сигнатурных методов требуют обязательного обновления</li>
            </ul>
            <div className="xdr-page__desc">
              Вышеперечисленное делает стандартные и популярные средства безопасности не только бесполезными, но и
              опасными, так как у пользователя возникает ложное представление о защищенности.
            </div>
          </div>
        </section>

        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__section-row">
              <div className="xdr-page__section-col">
                <img width="360px" src={require('../../images/image_xdr_1.png')} alt="" />
                <img width="360px" src={require('../../images/image_xdr_2.png')} alt="" />
              </div>
              <div className="xdr-page__section-col">
                <div className="xdr-page__title">
                  Наше решение - NEUROFORTRESS XDR, система предупреждения и обнаружения атак.
                </div>
                <p className="xdr-page__desc">
                  NeuroFortress XDR — это новый, альтернативный традиционным методам подход к обнаружению и реагированию
                  на инциденты, который объединяет процедуры обнаружения и реагирования в нескольких средах для
                  сокращения времени обнаружения и устранения атак, несанкционированного доступа к конфиденциальным
                  ресурсам компании, а также с расширенным функционалом Next-Generation Firewall и DLP-системы.
                </p>
                <p className="xdr-page__desc">
                  Данный продукт является современным средством, базирующимся на архитектуре защиты информации с момента
                  её рождения в инфраструктуре, контролирующим весь жизненный цикл созданной информации, используя
                  контейнеры данных и сквозной контроль за ними на уровне ядра операционных систем, что полностью
                  исключает утечку данных в результате несанкционированных действий или вредоносного доступа.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title">
              Наш комплекс систем включает в себя четыре основных системы и множество подсистем, связанных между собой и
              работающих в непрерывной синергии, чтобы добиться максимального уровня защиты.
            </div>
            <div className="xdr-page__scheme"></div>
            <div className="systems">
              <div className="systems__list">
                <div className="systems__system">
                  <Extender
                    title={
                      <div className="systems__system-title">
                        <div className="systems__system-icon NGXDR"></div>
                        <p>
                          <b>NGXDR</b> (New-Generation Extended Detection & Response) - обеспечивает защиту всего
                          предприятия, анализируя данные из любого источника для предотвращения изощрённых атак.
                          Оптимизация операций безопасности достигается с помощью единой платформы для обнаружения и
                          реагирования на все виды кибератак.
                        </p>
                      </div>
                    }>
                    <p className="xdr-page__desc">
                      NGXDR снижает общую стоимость владения в среднем на 44 % по сравнению с традиционными
                      разрозненными инструментами.
                    </p>
                    <p className="xdr-page__desc">
                      Стоимость владения NGXDR многократно покрывает убытки от успешных кибератак на предприятие.
                    </p>
                    <p className="xdr-page__desc">
                      NGXDR не только сократил количество инцидентов, которые нам приходилось рассматривать, но и время,
                      затрачиваемое на действия по этим инцидентам.
                    </p>
                  </Extender>
                </div>
                <div className="systems__system">
                  <Extender
                    title={
                      <div className="systems__system-title">
                        <div className="systems__system-icon NGIDS"></div>
                        <p>
                          <b>NGIDS</b> (New-Generation Intrusion Detection System) - Инновационная интеллектуальная
                          система идентификации кибератак на сетевую инфраструктуру, включая беспроводные среды
                          интернета вещей и умных устройств IoT.
                        </p>
                      </div>
                    }>
                    <p className="xdr-page__desc">
                      Инновационная интеллектуальная система идентификации кибератак на сетевую инфраструктуру, включая
                      беспроводные среды интернета вещей и умных устройств IoT.
                    </p>
                  </Extender>
                </div>
                <div className="systems__system">
                  <Extender
                    title={
                      <div className="systems__system-title">
                        <div className="systems__system-icon NGDLP"></div>
                        <p>
                          <b>NGDLP</b> (New-Generation Data Leak Prevention) - Инновационная система предотвращения
                          утечек информации и контроля внутренней сетевой структуры. Блокирует передачу и копирование
                          конфиденциальной информации с помощью алгоритмов ИИ, выявляет признаки корпоративного
                          мошенничества и нестандартного поведения, позволяет заниматься профилактикой инцидентов
                          безопасности.
                        </p>
                      </div>
                    }>
                    <div className="xdr-page__quote">
                      Контроль сотрудников и сетевой среды является не менее важным элементом кибербезопасности, чем
                      реагирование на внешние угрозы.
                    </div>
                    <p className="xdr-page__desc">
                      NGDLP — инновационная система предотвращения утечек информации и контроля внутренней сетевой
                      структуры. Блокирует передачу и копирование конфиденциальной информации с помощью алгоритмов ИИ,
                      выявляет признаки корпоративного мошенничества и нестандартного поведения, позволяет заниматься
                      профилактикой инцидентов безопасности.
                    </p>
                    <div className="xdr-page__sub-title">Основные функции NGDLP</div>
                    <ul className="xdr-page__list">
                      <li>Предотвращение утечек конфиденциальной информации </li>
                      <li>Выявление признаков корпоративного мошенничества </li>
                      <li>Определение аномального поведения</li>
                      <li>Своевременное и превентивное реагирование</li>
                      <li>Контроль скриншотов и фото с экрана</li>
                      <li>Контроль учтённых USB накопителей и других устройств </li>
                      <li>Контроль WEB доступа</li>
                      <li>Журнал всех действий пользователя и администратора </li>
                      <li>Контроль печати документов</li>
                      <li>Контроль присутствия работников на рабочем месте</li>
                    </ul>
                  </Extender>
                </div>
                <div className="systems__system">
                  <Extender
                    title={
                      <div className="systems__system-title">
                        <div className="systems__system-icon NGIPS"></div>
                        <p>
                          <b>NGIPS</b> (New-Generation Intrusion Prevention Systems) - усовершенствованная система
                          предотвращения вторжений.
                        </p>
                      </div>
                    }>
                    <p className="xdr-page__desc">
                      Анализ на уровне приложений: NGIPS анализирует содержимое сетевых пакетов на уровне приложений,
                      что позволяет выявлять сложные и целевые атаки.
                    </p>
                    <p className="xdr-page__desc">
                      Обнаружение и блокировка угроз в реальном времени. При обнаружении угрозы NGIPS мгновенно
                      принимает меры по ее блокировке.
                    </p>
                    <p className="xdr-page__desc">
                      Обеспечение всесторонней защиты сети, включая глубокий анализ трафика и проактивное предотвращение
                      угроз.
                    </p>
                  </Extender>
                </div>
                <div className="systems__system">
                  <Extender
                    title={
                      <div className="systems__system-title">
                        <div className="systems__system-icon NGCIPS"></div>
                        <p>
                          <b>NGCIPS</b> - Подсистема обеспечения безопасности периметра корпоративной инфраструктуры.
                        </p>
                      </div>
                    }>
                    <p className="xdr-page__desc">
                      Система обеспечения безопасности периметра корпоративной инфраструктуры.
                    </p>
                    <p className="xdr-page__desc">
                      Интегрированная инфраструктура кибербезопасности Neuro Fortress является ключевым элементом
                      защищенной корпоративной среды.
                    </p>
                    <p className="xdr-page__desc">
                      Системы коммуникаций защищены от вторжений Агентом информационной безопасности Neuro Fortress.
                    </p>
                  </Extender>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <video autoPlay loop muted className="xdr-page__video" src={require('../../images/video_bg.mp4')}></video>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title centered">Схема функционала подсистем приложения</div>
          </div>
          <div className="modules">
            <div className="modules__wrapper">
              {MODULES.map(module => {
                return (
                  <div className={`modules__col ${module.name}`}>
                    <div className="modules__col-title">{module.name}</div>
                    <div className="modules__col-desc">{module.desc}</div>
                    <div className="modules__col-list">
                      {module.items.map(item => {
                        return (
                          <div className={`modules__item ${module.name}`}>
                            <div className="modules__desc">{item}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="licence-list">
              <div className="xdr-page__title centered">Какие техники MITRE ATT&CK мы выявляем</div>
              <a href={mitreDoc} target="_blank" className="xdr-page__mitre-link"></a>
            </div>
            <div className="xdr-page__mitre-desc">версия ATT&CK v14</div>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title">Кто мы такие?</div>
            <p className="xdr-page__desc">
              Позвольте представить Вам нашу компанию «CVA Technologies», которая является разработчиком программного
              обеспечения и производителем современных электронных систем.
            </p>
            <p className="xdr-page__desc">
              Нашей компанией была создана система обнаружения и расследования инцидентов угроз безопасности нового
              поколения (NGIDS, Next Generation Intrusion Detection Systems), которая благодаря применению нейронных
              сетей и спецсредств беспроводного контроля является инновационной мерой противодействия кибератакам.
            </p>
            <p className="xdr-page__desc">
              По данному проекту был одобрен и успешно выполнен государственный Грант Старт-ЦТ. Программное обеспечение
              выполнено в соответствии с ТЗ, испытано и прошло экспертизу Фонда Содействия Инновациям. Получено
              свидетельство регистрации интеллектуальной собственности на программу NGIDS NeuroFortress.
            </p>
            <p className="xdr-page__desc">
              В рамках развития проекта мы, используя технологию искусственного интеллекта и уже успешно проверенные
              разработки, создали инновационную системы реагирования на киберугрозы, контроль сотрудников и сетевой
              среды, и систему обеспечения безопасности корпоративной инфраструктуры.
            </p>
            <p className="xdr-page__desc">
              Многолетний опыт работы позволяет решать любые задачи в разработке программного обеспечения. В своих
              разработках мы используем самые новейшие и передовые технологии, что позволит Вам получить совершенно
              новый уровень информационной безопасности.
            </p>
            <div className="xdr-page__title">Наше решение кибербезопасности предлагает:</div>
            <p className="xdr-page__desc">
              Обнаружение, расследование и реагирование на расширенные атаки на основе ИИ - Расширенная аналитика
              сокращает количество бессмысленных предупреждений и обнаруживает угрозы, которые пропускают ваши текущие
              инструменты.
            </p>
            <p className="xdr-page__desc">
              NF IoT - это интегрированная программа услуг и технологий, разработанная, чтобы помочь вашей организации
              на протяжении всего пути управления угрозами. Наше решение NF IoT помогает реализовать структуру NIST для
              доменов OT, IoT и IoMT, чтобы обеспечить прозрачность неуправляемых и подключенных устройств.
            </p>
            <p className="xdr-page__desc">
              Мы используем технологию, которая обнаруживает потенциальные угрозы в вашей среде - управляемые и
              неуправляемые устройства как в вашей сети, так и за ее пределами, а также в вашем воздушном пространстве.
              NF IoT объединяет возможности атакующих служб безопасности, управляемых служб безопасности, искусственного
              интеллекта, реагирования на инциденты и непрерывного совершенствования. NF IoT предлагает интегрированное
              управление жизненным циклом угроз и инцидентов.
            </p>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title">Нашими конкурентными преимуществами являются:</div>
            <ul className="xdr-page__cons">
              <li>Идентификация ранее неизвестных типов кибератак</li>{' '}
              <li>Параллельный анализ трафика различных информационных сред</li> <li>Высокая скорость реакции</li>
              <li>
                Возможность для нейронной сети полностью использовать потенциал «самообучения» и выходить за пределы
                базы знаний экспертной системы
              </li>
              <li>Отсутствие потребности регулярного обновления базы знаний</li>
              <li>Контроль беспроводных сетей и сред умных устройств (интернет-вещей)</li>
              <li>Планируется поддержка сертифицированных в РФ сред для интеграции (например, в рамках «ГосСОПКА»)</li>
            </ul>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title">NGFW NeuroFortress</div>
            <div className="xdr-page__section-row">
              <div className="xdr-page__section-col">
                <img width="360px" src={require('../../images/bg-fw-2.jpg')} alt="" />
              </div>
              <div className="xdr-page__section-col">
                <p className="xdr-page__desc">
                  Межсетевой экран нового поколения, объединяющий в себе высокую производительность, надежность и
                  удобство использования, с встроенной системой NGIDS на базе AI для анализа поведения и проактивного
                  реагирования на угрозы.
                </p>
                <a className="xdr-page__button-link" href="https://ngfw.neurofortress.ru/" target="_blank">
                  Узнать подробнее
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title">Вопросы и ответы</div>

            <Extender
              title={
                <div className="systems__system-title">
                  В обзоре решения есть пункт "расширенная аналитика". Хотелось бы подробней узнать о том, что она
                  включает и чем отличается от стандартной аналитики решения.
                </div>
              }>
              <p className="xdr-page__desc">
                Понятие расширенной аналитики легко продемонстрировать на примере работы подсистем NGIDS, NGIPS,
                HoneyPot, WAF:
              </p>
              <p className="xdr-page__desc">
                Стандартная аналитика предоставляет средства контроля, статистического анализа, сортировки, выборки,
                группировки инцидентов доступа к веб-приложениям (WAF), инцидентов IDS с группировкой по источнику
                воздействия и порту анализа, а также статистическую группировку и отбор в отчетах SIEM-подсистемы
                HoneyPot. Расширенная аналитика выполняет идентификацию и поиск корреляции атакующих воздействий на
                подсистемы путём построения графа атакующих воздействий. Таким образом, однозначно указывается источник
                комплексной атаки (сканирование, веб-инъекция, обращение к портам, брутфорс), а автоматическое
                реагирование либо ручное реагирование блокирует источник угроз по адресу, протоколу или сервису
                воздействия. Этот расширенный функционал особенно эффективно работает в среде нейросетевых детекторов
                NeuroFortress, где быстрая реакция и классификация без участия экспертного мнения позволяет увязать
                комплексную атаку с атакующим воздействием.
              </p>
            </Extender>

            <Extender
              title={
                <div className="systems__system-title">
                  На чем базируется "расследование и реагирование"? Это полностью программно-аппаратная функция, или
                  стандартная система с центром компетенций? Как много специалистов требуется содержать для реализации
                  данного функционала?
                </div>
              }>
              <p className="xdr-page__desc">
                Инновационность решений NeuroFortress заключается в отказе от классического центра компетенций и
                экспертного поля, требующего участия специалистов с различными навыками — от разработчиков антивирусных
                сигнатур до команд, отражающих атаки. Вместо этого используется многоуровневая нейросетевая система для
                идентификации, анализа и реагирования с точностью до 97% при обнаружении сетевых атак и вредоносных
                процессов. Ключевой особенностью NeuroFortress является мощное реагирование, реализованное через агенты,
                работающие на уровне ядра операционных систем конечных точек. Эти агенты применяют возможности ИИ для
                выявления и изоляции угроз в режиме реального времени.
              </p>
              <p className="xdr-page__desc">
                Таким образом расследование в системе выполняется путем прослеживания и корреляции результатов подсистем
                ИИ на конечных точках и статистике событий, хранящихся в системе сбора инцидентов на сервере.
                Инструменты расследования развиваются и дополняются, они могут дополняться при желании Заказчика, так
                как являются аналитическими надстройками над большими данными инцидентов и реагирований.
              </p>
            </Extender>

            <Extender
              title={
                <div className="systems__system-title">
                  Модуль IoT + IIoT. Почему выделен в отдельную категорию защиты? Как работа модуля отличается от работы
                  по стандартным протоколам и устройствам?
                </div>
              }>
              <p className="xdr-page__desc">
                Исключительная роль NeuroFortress в защите сетей IoT заключается в возможности анализа протоколов IoT на
                низком уровне радиоканала и протоколов нижнего уровня. Объединяя подсистему аппаратно-программных
                средств контроля и мониторинга радиоканала NF с преимуществами нейросетевой оценки и анализа
                характеристик радиочастотного взаимодействия устройств IoT, система кибербезопасности идентифицирует
                целый ряд атак на радиоканал, протокол нижнего уровня и сетевое взаимодействие (сетевое взаимодействие -
                относительно средств NGIDS/NGIPS системы).
              </p>
            </Extender>

            <Extender
              title={
                <div className="systems__system-title">
                  Каким образом достигается высокая производительность при большой нагрузке (трафике). Есть ли сравнение
                  в производительности с основными конкурентами?
                </div>
              }>
              <p className="xdr-page__desc">
                Высокая производительность подсистем анализа и контроля трафика, идентификации сетевых атак обусловлена:
                <ul>
                  <li>
                    использованием специализированных драйверов ОС Linux/Windows для контроля трафика (для Windows —
                    получивших высокую оценку авторизованного лицензирования, для Linux — подтвержденную протоколами
                    сравнения с рядом способов сбора трафика на уровне ядра ОС Linux)
                  </li>
                  <li>
                    использованием патентованных нейронных сетей, специально оптимизированных для анализа сетевого
                    трафика (реализация на C++, в том числе для спецвычислителей и систем на базе XILINX)
                  </li>
                  <li>распределённостью системы сбора инцидентов на базе агентов</li>
                </ul>
                Высокая производительность идентификации вредоносных воздействий и системы реагирования DLP обусловлена:
                <ul>
                  <li>
                    использованием для ОС Windows специфицированных драйверов уровня ядра ОС, получивших высокую оценку
                    авторизационной комиссии и подпись EV Microsoft
                  </li>
                  <li>
                    использованием на конечных точках агентов патентованной нейронной сети, специально разработанной для
                    идентификации угроз утечек данных, шифраторов и майнеров
                  </li>
                </ul>
              </p>
            </Extender>

            <Extender
              title={
                <div className="systems__system-title">
                  Решение включает в себя большое количество модулей и функций. В описании есть пункт, что решение
                  подходит для МСБ. Есть ли модель реализации решения в организации с малым ИБ бюджетом?
                </div>
              }>
              <p className="xdr-page__desc">
                NF достаточно гибко конфигурируется в необходимый комплект систем и подсистем информационной
                безопасности. Так же для малой сети есть возможность развернуть NGFW на базе умного роутера, на борту
                которого присутствует NF, с подключением 6/12 ПК.
              </p>
            </Extender>

            <Extender
              title={
                <div className="systems__system-title">
                  Есть ли демо\тестовая лицензия? Можно ли ознакомиться с интерфейсом?
                </div>
              }>
              <p className="xdr-page__desc">Да, мы проводим пилоты.</p>
            </Extender>
          </div>
        </section>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__section-row">
              <div className="xdr-page__section-col">
                <img style={{marginTop: 44}} width="120px" src={require('../../images/minkomsvyaz_0.png')} alt="" />
                <img width="120px" src={require('../../images/fstec.png')} alt="" />
              </div>
              <div className="xdr-page__section-col">
                <div className="xdr-page__title">Наши лицензии</div>
                <div className="licence-list">
                  <div className="licence-list__title">РЕЕСТР РОССИЙСКОГО ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ</div>
                  <div className="licence-list__items">
                    <a href="https://reestr.digital.gov.ru/reestr/1765724/" className="licence-list__item">
                      NEUROFORTRESS XDR № 18928
                    </a>
                    <a href="https://reestr.digital.gov.ru/reestr/1807046/" className="licence-list__item">
                      Менеджер паролей NF MP № 19223
                    </a>
                    <a href="https://reestr.digital.gov.ru/reestr/1765620/" className="licence-list__item">
                      NF Secure Instant Messenger № 18879
                    </a>
                    <a href="https://reestr.digital.gov.ru/reestr/2038723/" className="licence-list__item">
                      NG Web Application Firewall № 20653
                    </a>
                  </div>
                </div>
                <div className="licence-list">
                  <div className="licence-list__title">Лицензии ФСТЭК</div>
                  <div className="licence-list__items">
                    <a
                      href="https://reestr.fstec.ru/regview1?guid=8a685796-6afd-49ba-b99b-23ce5bbf3c88"
                      className="licence-list__item">
                      Л024-00107-77/00651158
                    </a>
                    <a
                      href="https://reestr.fstec.ru/regview2?guid=1a8a110d-c38c-4a22-b5f1-a39a9e4e2678"
                      className="licence-list__item">
                      Л050-00107-77/00651157
                    </a>
                  </div>
                </div>
                <div className="licence-list">
                  <div className="xdr-page__title">Наши продукты удовлетворяют требованиям ГосСОПКА</div>
                  <p className="xdr-page__desc">
                    Программное обеспечение компании CVA Technologies может использоваться в следующих информационных
                    системах:
                    <ul>
                      <li>КИИ 1 категории</li>
                      <li>ГИС 1 класса</li>
                      <li>АСУТП 1 класса</li>
                      <li>ИСПДн 1 уровня</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="xdr-page__bottom">
          <a
            className="xdr-page__bottom-button"
            href="https://cvatec.com/index.php/ru/neurofortress-ru/informatsiya-o-produktakh-neurofortress">
            КУПИТЬ NEUROFORTRESS XDR
          </a>
        </div>
        <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__title centered">Контактная информация</div>
            <div className="xdr-page__section-row">
              <div className="xdr-page__section-col">
                <ContactForm formMethod={submitForm} />
              </div>
              <div className="xdr-page__section-col">
                <Contacts />
              </div>
            </div>
          </div>
        </section>
        <Map />
        {/* <section className="xdr-page__section">
          <div className="xdr-page__section-wrapper">
            <div className="xdr-page__section-row">
              <div className="xdr-page__section-col">
                <ContactForm formMethod={submitForm} />
              </div>
              <div className="xdr-page__section-col">
                <Contacts />
              </div>
            </div>
          </div>
        </section>
        <Map /> */}
      </div>
    </div>
  )
}

export default xdrPage
