import React, {useState} from 'react'

import './style.scss'

const Extender = ({children, title = 'Подробнее'}) => {
  const [isShow, setShow] = useState(false)

  return (
    <div className="extender">
      <div className="extender__button" onClick={() => setShow(!isShow)}>
        {title}
      </div>
      {isShow && <div className="extender__wrapper">{children}</div>}
    </div>
  )
}

export default Extender
