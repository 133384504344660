import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'

// import IndexPage from './pages/indexPage'

import xdrPage from './pages/xdrPage'
import ErrorPage from './pages/ErrorPage'

// import Header from './components/blocks/Header'
import Footer from './components/blocks/Footer'
import {UserContextProvider} from './context/UserContext'

// import {ProfileToken} from './helpers/token'

import './App.css'
import 'normalize.css'

function App({history}) {
  // const checkLogin = () => {
  //   return ProfileToken.get()
  // }

  return (
    <div className="App">
      <UserContextProvider>
        {/* <Header history={history} /> */}
        <Switch>
          {/* <Route exact path="/" component={IndexPage} /> */}
          <Route exact path="/" component={xdrPage} />
          {/* <Redirect from="/" to="/cabinet" /> */}
          <Route path="/404" component={ErrorPage} />
          <Redirect from="*" to="/404" />
        </Switch>
        <Footer history={history} />
      </UserContextProvider>
    </div>
  )
}

export default withRouter(App)
